.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-content {
  max-width: 600px;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(23, 44, 99, 0.09);
  border-radius: 6px;
}

.modal-content h3 {
  font-weight: 600;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 25px;
}

.modal-content pre {
  font-size: 14px;
  line-height: 200%;
  white-space: pre-wrap;
}

.modal-content a {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;

  color: #836efb;
  text-decoration: none;
}
