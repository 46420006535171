.search_bar {
	margin: 12px;
	background: #FFFFFF;
	box-shadow: 0px 5px 25px rgba(23, 44, 99, 0.09);
	border-radius: 6px;
	padding: 12px;
	top:0;
	left:0;
	z-index: 1;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	height: 20%;
	text-align: center;
	align-items: center;
	justify-content: center;
} 

.input_fields {
  flex: 2;
  min-width: 5em;
  padding: 1%;
}

.text_field {
	padding: 9.5px 8px;
	font-size: 15px;
	border-color: #D3D3D3;
	border-radius: 5px;
	border-width: 1px;
}

.search-button {
  width: 176px;
  height: 44px;
  background: #836EFB;
  border-radius: 8px;
  color: #FFFFFF;
  border:none;
}

.App-ad-pane {
  height: auto;
  z-index: 2;
  overflow-x: hidden;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.ad-container {
  background: #ffffff;
  margin: 1%;
  padding: 1%;
  width: 22%;
  min-width: 20%;
  float: left;
  border-radius: 1%;
  display: flex;
  flex-direction: column;
  border-top: 5px solid #836EFB;
  box-shadow: 10px 10px #F8F8FF;
}

.ad-image-container {
  background: #ffffff;
  margin: 1%;
  padding: 1%;
  display: flex;
  width: 40%;
  min-width: 20%;
  flex-direction: column;
  border-top: 5px solid #836EFB;
  box-shadow: 10px 10px #F8F8FF;
}

.row-flex {
  display: flex;
  flex-direction: row;
}

.value-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wide {
  width: 100%;
  border-radius: 0%;
}

.components {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.values {
  background: #E3CEF6;
  border-radius: 5px;
  color: black;
  border: none;
  width: auto;
  padding: 2%;
  margin: 1%;
  margin-left: 0%;
  text-align: center;
  cursor: default;
}

img {
  margin-top: 2%;
  max-width: 100%;
  height: auto;
}

.dates {
  font-size: small;
  font-weight: bold;
  text-align: center;
}

.ad-container-details {
  font-weight: bold;
  padding: 0.5%;
  font-size: small;
}

.spend {
  font-size: x-large;
  padding-top: 3%;
  padding-bottom: 3%; 
}

.other-values {
  font-size: large;
  font-weight: bolder;
  padding: 0.5%;
  padding-top: 0%;
  margin-top: 0%;
}

.read-more {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #836EFB;
  cursor: pointer;
}

.big{
  font-size: large;
  font-weight: bold;
}

.left-gap{
  padding-left: 1%;
}

.previous {
  background-color: #f1f1f1;
  color: black;
}

.next {
  background-color: #836EFB;
  color: white;
}

.round {
  border-radius: 60%;
  border: none;
  font-size: xx-large;
  display: inline-block;
  padding: 8px 16px;
}

.empty {
  padding: 5%;
  font-size: x-large;
}

table {
  width: 100%;
}

td, tr {
  padding: 2%;
  font-weight: normal;
}

.orange{
  font-weight: bold;
  color: #FF646A;
}

.carousel{
  display: flex;
  flex-direction: row;
  overflow: scroll;
  width: 100%;
}

.gradient{
  height:200px;
  width:200px;
  background:linear-gradient(to right, rgba(255,255,255,1),transparent);
  background-color:#ff646a; /*this your primary color*/
}

.pointer{
  cursor: pointer;
}

.text_field:disabled, .search-button:disabled {
  background: #E6E6E6;
}