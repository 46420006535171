* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#body {
  margin: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-tooltip {
  background: #ffffff;
  box-shadow: 0px 15px 25px rgba(23, 44, 99, 0.21);
  border-radius: 8px;
  padding: 6px 12px;
  font-style: normal;
  text-align: left;
}

.custom-tooltip > div {
  min-width: 70px;
  text-align: left;
}

.custom-tooltip p {
  margin: 0;
  margin-block-end: 0;
  margin-block-start: 0;
  line-height: 1;
}

.custom-tooltip span {
  margin-left: 0.5rem;
  font-size: 18px;
}

.custom-tooltip p.label {
  font-weight: 600;
  font-size: 12px;
  color: rgba(21, 28, 70, 0.5);
  min-width: 70px;
  text-align: left;
}

.custom-tooltip p.title {
  font-weight: bold;
  font-size: 12px;
  color: #151c46;
}

table {
  border-collapse: collapse;
}

table {
  border-top: 1px dashed rgba(45, 59, 115, 0.1);
  border-bottom: 1px dashed rgba(45, 59, 115, 0.1);
}

table tr td,
table tr th {
  border-left: 1px dashed rgba(45, 59, 115, 0.1);
}

table tr td:last-child,
table tr th:last-child {
  border-right: 1px dashed rgba(45, 59, 115, 0.1);
}

table tr:nth-child(even) {
  background: rgba(247, 248, 251, 0.64);
}

table.polads__table__container {
  font-style: normal;
  font-size: 12px;
  width: 100%;
}

table.polads__table__container tr.polads__table__row {
  text-align: center;
}

tr:first-child {
  font-weight: 600;
}

table.polads__table__container tr.polads__table__row td.polads__table__cell {
  padding: 1rem;
}

.polads__table__body .polads__table__cell:first-child {
  text-align: left;
}

/* For screens with graphs */
main.polads__graph_views {
  max-width: 1440px;
  margin: 81px auto;
  min-height: 600px;
  padding: 0 1.5rem;
  position: relative;
}

main.senate__graph_views {
  max-width: 1440px;
  margin: auto;
  margin-top: 81px;
  min-height: 600px;
  padding: 0 1.5rem;
  position: relative;
  overflow: hidden;
}

@media (max-width: 480px) and (min-width: 320px) {
  main.polads__graph_views,
  main.senate__graph_views {
    margin-top: 0;
  }
}

.download-csv {
  background: #836efb;
  color: #ffffff;
  text-align: center;
  outline: none;
  cursor: pointer;
}

.download-csv:hover {
  opacity: 0.8;
}

.download-csv.download-csv__header {
  position: absolute;
  right: 1.5rem;
  top: 1.25rem;

  font-size: 18px;
  box-shadow: 0px 10px 25px rgba(96, 40, 186, 0.34);
  border-radius: 12px;
  width: 197px;
  padding: 20px 0;
}

@media (min-width: 1440px) {
  main.polads__graph_views {
    padding: 0;
  }

  .download-csv.download-csv__header {
    right: 0;
  }
}

span.polads__icon {
  font-family: 'icomoon' !important;
}

.static-progress-bar__base {
  width: 68px;
  height: 8px;
  background: linear-gradient(0deg, #e1e8f4, #e1e8f4),
    linear-gradient(0deg, #e1e8f4, #e1e8f4), #e1e8f4;
  border-radius: 0px 1px 1px 0px;
  display: inline-block;
}

.static-progress-bar__progress {
  height: 8px;
  border-radius: 0px 1px 1px 0px;
}

select:disabled {
  cursor: not-allowed;
}

h1 {
  font-size: 36px !important;
}

h2 {
  font-size: 30px !important;
}

h3 {
  font-size: 24px !important;
}

h4 {
  font-size: 20px !important;
}

h5 {
  font-size: 18px !important;
}

h6 {
  font-size: 16px !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.app-alert {
  background: #eee;
  font-size: 14px;
  padding: 1rem 3rem 1rem 1rem;
  position: relative;
}

.app-alert p {
  margin: 0.5rem 0;
}

.app-alert .close {
  right: 1rem;
  position: absolute;
  top: 1 rem;
}
