.map-graph__container {
  margin: 0 1.5rem;
  text-align: center;
}

.map-graph__container .us-state-map {
  max-width: 100%;
}

path.state {
  stroke: #000000;
}

path.state:hover,
g.state:hover {
  opacity: 0.5;
  cursor: pointer;
}

g.state {
  stroke: #000000;
}

.mapLegend {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  position: relative;
}

.gradientColor {
  width: 240px;
  height: 14px;
  margin: 0 0.25rem;
}

.tooltip-content-wrapper {
  min-width: 165px;
  padding: 1rem;

  background: #ffffff;
  box-shadow: 0px 15px 25px rgba(99, 50, 23, 0.21);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
}

.tooltip-title {
  text-align: right;
  font-size: 12px;
}

.tooltip-title p {
  margin: 0;
  color: rgba(21, 28, 70, 0.5);
}

.tooltip-spend {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-left: 0.5rem;
}

.map__source {
  font-size: 14px;
}
