.polads__political_ads__component {
  margin: 4rem 1rem;
  display: flex;
  flex-wrap: wrap;
}

.polads__political_ads__table_container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(23, 44, 99, 0.09);
  border-radius: 6px;
}

.polads__political_ads__component h3.political_ads {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 98.8%;

  color: #151c46;
}

.polads__political_ads__component p.political_ads__content {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 195.3%;
}

table {
  width: 100%;
}

.polads__political_ads__table_container table tr td:nth-child(3),
.polads__political_ads__table_container table tr td:nth-child(4) {
  display: none;
}

.polads__political_ads__component .polads__graph_header {
  border-bottom: none;
  margin-bottom: 0;
}

.polads__political_ads__component
  .polads__graph_header
  .polads__graph_header__title_container {
  margin-left: 0.5rem;
}

.polads__political_ads__component
  .polads__graph_header
  .polads__graph_header__filters_container {
  margin-right: 0.5rem;
}

td.polads__table__cell .polads__political_ads__table_tag {
  font-size: 10px;
  width: 100%;
  text-overflow: ellipsis;
  box-shadow: none;
  overflow: hidden;
}

@media (min-width: 1024px) {
  /* Political Ads */
  .polads__political_ads__component {
    display: flex;
    flex: 1;
    min-height: 740px;
    width: 100%;
    min-width: 1024px;
    max-width: 1440px;
    margin: 4rem auto;
    position: relative;
  }

  .polads__political_ads__container {
    text-align: left;
    max-width: 450px;
    margin: auto;
    position: absolute;
    top: 195px;
    bottom: 0;
    left: 27px;
    right: 0;
    top: 0;
    max-width: 600px;
  }

  .polads__political_ads__table_relative_container {
    position: relative;
    width: 100%;
  }

  .polads__political_ads__table_container {
    display: inline-block;
    width: fit-content;
    padding: 0.5rem 1rem 1rem;
    margin-left: 12px;
  }

  .polads__table {
    width: 46% !important;
  }

  .polads__political_ads__table_tag {
    min-width: 86px;
    background: #2d3b73;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 0.375rem 1rem;
    height: 23px;
    box-shadow: none;
  }

  b.polads__political_ads__table_bold {
    font-weight: bold;
    font-size: 12px;
  }

  .polads__political_ads__component
    .polads__graph_header
    .polads__graph_header__title_container {
    margin-left: 0;
  }

  .polads__political_ads__component
    .polads__graph_header
    .polads__graph_header__filters_container {
    margin-right: 0;
  }

  table {
    min-width: 420px;
  }
}

@media (min-width: 1280px) {
  table {
    min-width: 500px;
  }

  .polads__political_ads__table_container table tr td:nth-child(3),
  .polads__political_ads__table_container table tr td:nth-child(4) {
    display: table-cell;
  }
}

@media (min-width: 1440px) {
  table {
    min-width: 600px;
  }
}
