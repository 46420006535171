@font-face {
  font-family: 'ProximaNova-Regular';
  src: local('ProximaNova-Regular'),
    url('../assets/fonts/proxima_ssv/ProximaNova-Regular.otf')
      format('opentype');
}

body {
  margin: 0;
  font-family: 'ProximaNova-Regular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

button {
  outline: none;
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.pointer {
  cursor: pointer !important;
}

.underline:hover {
  text-decoration: underline !important;
}

.no-underline {
  text-decoration: none !important;
  color: #836efb;
  font-weight: bold;
}

.nav-menu {
  overflow-x: hidden !important;
}

.main-layout {
  max-width: 1440px;
  margin: 80px auto;
  min-height: 600px;
  padding: 0 1.5rem;
  position: relative;
}

@import 'screens/NotificationsScreen.css';
